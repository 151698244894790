<template>
    <div id="page-lap-penyakit">
      <b-container fluid>
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <b-card>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="4" xl="4">
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Dari
                    </template>
                    <b-form-datepicker
                      style="width: 100%"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      no-flip
                      locale="id"
                      v-model="data_search.tanggal_mulai"
                    ></b-form-datepicker>
                  </b-form-group>

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Sampai
                    </template>
                    <b-form-datepicker
                      style="width: 100%"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      no-flip
                      locale="id"
                      v-model="data_search.tanggal_selesai"
                    ></b-form-datepicker>
                  </b-form-group>

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Asuransi
                    </template>
                    <b-form-select
                      :options="option_asuransi"
                      size="sm"
                      v-model="data_search.asuransi"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" sm="12" md="12" lg="4" xl="4">
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Pemeriksaan Lab
                    </template>
                    <multiselect
                      :options="options_pemeriksaan_lab"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nama_sub_tindakan_lab"
                      size="sm"
                      v-model="data_search.tindakan_lab"
                    ></multiselect>
                  </b-form-group>

                  <!-- <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Poli
                    </template>
                    <multiselect
                      :options="options_poli"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nama_sub_tindakan"
                      size="sm"
                      v-model="data_search.tindakan_lab"
                    ></multiselect>
                  </b-form-group> -->
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <hr>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <div style="display:flex;justify-content:flex-start">
                    <b-button @click="getDataKunjungan()" variant="primary" class="mr-2">Tampilkan</b-button>
                    <!-- <b-button variant="success" class="mr-2">Export to Excel</b-button>
                    <b-button variant="warning">Reset</b-button> -->
                  </div>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <hr>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <h5 style="text-align:center"><strong>LAPORAN HARIAN - PEMERIKSAAN LABORATORIUM</strong></h5>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <b-table-simple responsive striped>
                    <b-thead>
                      <b-tr>
                        <b-th style="width: 10px; position:sticky; left:0; min-width:50px; background-color: #fff;">No.</b-th>
                        <b-th style="position:sticky; left:50px; min-width:150px; background-color: #fff;">Tanggal</b-th>
                        <b-th style="position:sticky; left:200px; min-width:160px; background-color:#fff">Nama Pasien</b-th>
                        <b-th style="min-width: 130px;">TTL</b-th>
                        <b-th style="min-width: 130px;">NIK</b-th>
                        <b-th style="min-width: 130px;">Alamat</b-th>
                        <b-th style="min-width: 130px;">No. eRM</b-th>
                        <b-th style="min-width: 130px;">Jenis Kelamin</b-th>
                        <b-th style="min-width: 130px;">Umur</b-th>
                        <b-th style="min-width: 130px;">Asuransi</b-th>
                        <b-th style="min-width: 130px;">Ruangan Asal</b-th>
                        <b-th style="min-width: 130px;">Penanggung Jawab</b-th>
                        <b-th style="min-width: 130px;">Pemeriksa</b-th>
                        <b-th style="min-width: 170px;">Tanggal Selesai</b-th>
                        <b-th style="min-width: 170px;">Pemeriksaan</b-th>
                        <b-th style="min-width: 170px;">Hasil</b-th>
                        <b-th style="min-width: 170px;">Nilai</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-for="(item, idx) in items" :key="idx">
                        <b-td v-if="item.type == 'head'" :rowspan="item.sub_pemeriksaan.length > 1 ? item.sub_pemeriksaan.length : 1" style="position:sticky;left:0;min-width:50px;background-color: #fff;">{{ item.no }}</b-td>
                        <b-td v-if="item.type == 'head'" :rowspan="item.sub_pemeriksaan.length > 1 ? item.sub_pemeriksaan.length : 1" style="position:sticky;left:50px;min-width:150px;background-color: #fff;">{{ item.tgl }}</b-td>
                        <b-td v-if="item.type == 'head'" :rowspan="item.sub_pemeriksaan.length > 1 ? item.sub_pemeriksaan.length : 1" style="position:sticky;left:200px;min-width:160px;background-color: #fff;">{{ item.nama_lengkap }}</b-td>
                        <b-td v-if="item.type == 'head'" :rowspan="item.sub_pemeriksaan.length > 1 ? item.sub_pemeriksaan.length : 1">{{ item.ttl }}</b-td>
                        <b-td v-if="item.type == 'head'" :rowspan="item.sub_pemeriksaan.length > 1 ? item.sub_pemeriksaan.length : 1">{{ item.nik }}</b-td>
                        <b-td v-if="item.type == 'head'" :rowspan="item.sub_pemeriksaan.length > 1 ? item.sub_pemeriksaan.length : 1">{{ item.alamat_sekarang }}</b-td>
                        <b-td v-if="item.type == 'head'" :rowspan="item.sub_pemeriksaan.length > 1 ? item.sub_pemeriksaan.length : 1">{{ item.no_rm }}</b-td>
                        <b-td v-if="item.type == 'head'" :rowspan="item.sub_pemeriksaan.length > 1 ? item.sub_pemeriksaan.length : 1">{{ item.jenis_kelamin == 'P' ? 'Perempuan' : 'Laki - Laki' }}</b-td>
                        <b-td v-if="item.type == 'head'" :rowspan="item.sub_pemeriksaan.length > 1 ? item.sub_pemeriksaan.length : 1">{{ item.umur }}</b-td>
                        <b-td v-if="item.type == 'head'" :rowspan="item.sub_pemeriksaan.length > 1 ? item.sub_pemeriksaan.length : 1">{{ item.asuransi }}</b-td>
                        <b-td v-if="item.type == 'head'" :rowspan="item.sub_pemeriksaan.length > 1 ? item.sub_pemeriksaan.length : 1">{{ item.rujukan_dari }}</b-td>
                        <b-td v-if="item.type == 'head'" :rowspan="item.sub_pemeriksaan.length > 1 ? item.sub_pemeriksaan.length : 1">{{ item.nama_penanggung_jawab }}</b-td>
                        <b-td v-if="item.type == 'head'" :rowspan="item.sub_pemeriksaan.length > 1 ? item.sub_pemeriksaan.length : 1">{{ item.nama_pemeriksa }}</b-td>
                        <b-td v-if="item.type == 'head'" :rowspan="item.sub_pemeriksaan.length > 1 ? item.sub_pemeriksaan.length : 1">{{ item.tgl_selesai }}</b-td>
                        <b-td v-if="item.type == 'head'">{{ item.nama_pemeriksaan_lab }}</b-td>
                        <b-td v-if="item.type == 'head'">{{ item.hasil_pemeriksaan }}</b-td>
                        <b-td v-if="item.type == 'head'">{{ item.nilai_pemeriksaan }}</b-td>
                        
                        <b-td v-if="item.type == 'sub'">{{ item.nama_pemeriksaan_lab }}</b-td>
                        <b-td v-if="item.type == 'sub'">{{ item.hasil_pemeriksaan }}</b-td>
                        <b-td v-if="item.type == 'sub'">{{ item.nilai_pemeriksaan }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </template>
  
  <script>
  export default {
    name: "kunjungan_pasien",
    data() {
      return {
        showing: false,
        variant: "success",
        msg: "",
        value: null,
        total_tarif: 0,
        total_jumlah: 0,
        total_total: 0,
        options_pemeriksaan_lab: [],
        data_search: {
          tanggal_mulai: null,
          tanggal_selesai: null,
          tindakan_lab: null,
          asuransi: null,
          halaman: 0,
          jumlah: 1000,
        },
        option_asuransi: [
          {value: null, text: "Semua"},
          {value: "UMUM", text: "Umum"},
          {value: "BPJS", text: "BPJS Kesehatan"},
          // {value: "", text: "Pemerintah Daerah"},
          // {value: "", text: "Hibah Pemba"},
        ],
        items: [],
        checked: false,
        checked_poli: "",
        
      };
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter((f) => f.sortable)
          .map((f) => {
            return { text: f.label, value: f.key };
          });
      },
      filterOn() {
        // console.log(this.fields.map(el => el.key))
        return this.fields.map(el => el.key)
      },
    },
    activated(){
      this.data_search.tanggal_mulai = this.$moment(new Date()).format("YYYY-MM-DD")
      this.data_search.tanggal_selesai = this.$moment(new Date()).format("YYYY-MM-DD")
    //   this.getKecamatan()
      this.getData();
      this.getDataKunjungan()
    },
    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async getData(){
        let vm = this
        let tenaga_medis = await vm.$axios.post("/sub_tindakan_lab/list")
        console.log(tenaga_medis, 'tindakan lab');
        if(tenaga_medis.data.data.length){
            vm.options_pemeriksaan_lab = tenaga_medis.data.data
            // console.log(vm.options_tenaga_medis);
        }
      },
      getDataKunjungan(){
        let vm = this
        if(vm.data_search.tenaga_medis){
          vm.data_search.tenaga_medis_id = vm.data_search.tenaga_medis.tenaga_medis_id
        }
        if(vm.data_search.tindakan_lab){
          vm.data_search.sub_tindakan_lab_id = vm.data_search.tindakan_lab.sub_tindakan_lab_id
        }
        vm.$axios.post("/pemeriksaan_lab/laporan_pelayanan_laboratorium", vm.data_search)
        .then((res) => {
          console.log(res, 'ini respon');
          if(res.data.data.length){
            vm.items = []
            const items = []
            for (let i = 0; i < res.data.data.length; i++) {
                let x = res.data.data[i];
                x.no = i+1
                x.tgl = vm.$moment(x.tanggal_pemeriksaan_lab).format("DD-MM-YYYY HH:mm")
                x.tgl_selesai = vm.$moment(x.tanggal_selesai_pemeriksaan_lab).format("DD-MM-YYYY HH:mm")
                x.ttl = `${x.tempat_lahir}, ${vm.$moment(x.tanggal_lahir).format("DD-MM-YYYY")}`
                x.umur = `${x.usia_tahun} tahun ${x.usia_bulan} bulan ${x.usia_hari} hari`
                x.type = 'head'
                x.nama_pemeriksaan_lab = x.sub_pemeriksaan[0].nama_pemeriksaan_lab
                x.hasil_pemeriksaan = x.sub_pemeriksaan[0].hasil_pemeriksaan
                x.nilai_pemeriksaan = x.sub_pemeriksaan[0].nilai_pemeriksaan
                items.push(x)
                for (let j = 0; j < x.sub_pemeriksaan.length; j++) {
                    if(j == 0) continue
                    let y = x.sub_pemeriksaan[j];
                    y.type = 'sub'
                    items.push(y)
                }
            }
            console.log(items, 'ini itemsnya');
            vm.items = items
          }else {
            vm.items = []
            vm.$store.commit('set_alert', {msg: "TIDAK ADA DATA", variant: "danger", showing: true})
          }
          
        })
        .catch((err) => {
          vm.$store.commit('set_alert', {msg: err.message, variant: "danger", showing: true})
        })
      },
      toggleSelect: function () {
            let select = this.checked;
            console.log(this.checked);
            this.options_puskesmas.forEach(function (data) {
                data.checked = select;
            });
            this.select_puskesmas = []
            for (let i = 0; i < this.options_puskesmas.length; i++) {
              let x = this.options_puskesmas[i];
              if(x.checked == true){
                this.select_puskesmas.push(x.puskesmas_id)
              }
            }
            this.$forceUpdate()
        },
        toggleSelectPoli: function () {
            let select = this.checked_poli;
            // console.log(this.checked_poli);
            this.options_poli.forEach(function (data) {
                data.checked = select;
            });
            this.select_poli = []
            for (let i = 0; i < this.options_poli.length; i++) {
              let x = this.options_poli[i];
              if(x.checked == true){
                this.select_poli.push(x.ms_poli_id)
              }
            }
            this.$forceUpdate()
        },
        addSelect(){  
          let vm = this
          vm.select_puskesmas = []
          for (let i = 0; i < vm.options_puskesmas.length; i++) {
              let x = vm.options_puskesmas[i];
              if(x.checked == true){
                vm.select_puskesmas.push(x.puskesmas_id)
              }
            }
        },
        addSelectPoli(){  
          let vm = this
          vm.select_poli = []
          for (let i = 0; i < vm.options_poli.length; i++) {
              let x = vm.options_poli[i];
              if(x.checked == true){
                vm.select_poli.push(x.ms_gudang_id)
              }
            }
        },
      triggerAlert(event) {
        let vm = this;
        vm.$store.commit("set_alert", event);
        vm.getDatas()
      },
    },
  };
  </script>
  <style>
  #page-lap-penyakit .custom-table td{
    padding:0 !important;
    font-size:14px;
    font-weight:bold;
    border:hidden !important;
  }

  #page-lap-penyakit .custom-control-label{
    padding-top:2.5px
  }
  </style>
  